var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-list-container" },
    [
      _c("SearchForm", {
        attrs: {
          "label-width": "60",
          query: _vm.query,
          "search-column": _vm.searchColumn,
        },
        on: { getTableData: _vm.getTableData, resetForm: _vm.resetForm },
      }),
      _c("TablePaging", {
        attrs: {
          "button-list": _vm.buttonList,
          "column-list": _vm.columnList,
          loading: _vm.loading,
          "max-height": _vm.maxHeight,
          "page-info": _vm.pageInfo,
          "table-data": _vm.list,
        },
        on: {
          "update:tableData": function ($event) {
            _vm.list = $event
          },
          "update:table-data": function ($event) {
            _vm.list = $event
          },
          handleCopy: _vm.handleCopy,
          handleCurrentChange: _vm.handleCurrentChange,
          handleEdit: _vm.handleEdit,
          handleManage: _vm.handleManage,
          handleRecommend: _vm.handleRecommend,
          handleRelease: _vm.handleRelease,
          handleRemove: _vm.handleRemove,
        },
        scopedSlots: _vm._u([
          {
            key: "image",
            fn: function (ref) {
              var row = ref.row
              return [
                row.cover_pic_arr.length
                  ? _c("el-image", {
                      staticClass: "activity-list-cover",
                      attrs: {
                        "preview-src-list": [row.cover_pic_arr[0].url],
                        src: row.cover_pic_arr[0].url,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
          {
            key: "click",
            fn: function (ref) {
              var row = ref.row
              return [_vm._v(_vm._s(row.click) + "/" + _vm._s(row.click_show))]
            },
          },
          {
            key: "publish_time",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("dateFormat")(row.publish_time, true))),
                ]),
              ]
            },
          },
          {
            key: "sort",
            fn: function (ref) {
              var row = ref.row
              return [
                _c("el-date-picker", {
                  attrs: { placeholder: "选择日期时间", type: "datetime" },
                  on: {
                    change: function ($event) {
                      return _vm.handleChangeTime(row)
                    },
                  },
                  model: {
                    value: row.rank_time,
                    callback: function ($$v) {
                      _vm.$set(row, "rank_time", $$v)
                    },
                    expression: "row.rank_time",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: row.rank_time > _vm.nowTime,
                        expression: "row.rank_time > nowTime",
                      },
                    ],
                  },
                  [
                    _vm._v(
                      " 距离置顶结束:" +
                        _vm._s(_vm._f("timeBetween")(row.rank_time)) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }