const config = {
  // 表格最大高度
  nowTime: new Date().getTime(),
  maxHeight: document.body.clientHeight - 340,
  query: {
    type: 1,
    keywords: null,
  },
  buttonList: Object.freeze([
    {
      label: '发布新活动',
      type: 'primary',
      icon: 'el-icon-plus',
      eventName: 'handleRelease',
    },
    {
      label: '复制推广链接',
      type: 'primary',
      icon: 'el-icon-document-copy',
      eventName: 'handleCopy',
    },
  ]),
  typeOptions: Object.freeze([
    {
      id: 1,
      label: '全部活动',
    },
    {
      id: 2,
      label: '进行中',
    },
    {
      id: 3,
      label: '已结束',
    },
  ]),
}

export default config
