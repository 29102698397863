<template>
  <div class="activity-list-container">
    <SearchForm
      label-width="60"
      :query="query"
      :search-column="searchColumn"
      @getTableData="getTableData"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <TablePaging
      :button-list="buttonList"
      :column-list="columnList"
      :loading="loading"
      :max-height="maxHeight"
      :page-info="pageInfo"
      :table-data.sync="list"
      @handleCopy="handleCopy"
      @handleCurrentChange="handleCurrentChange"
      @handleEdit="handleEdit"
      @handleManage="handleManage"
      @handleRecommend="handleRecommend"
      @handleRelease="handleRelease"
      @handleRemove="handleRemove"
    >
      <!-- 图片 -->
      <template #image="{ row }">
        <el-image
          v-if="row.cover_pic_arr.length"
          class="activity-list-cover"
          :preview-src-list="[row.cover_pic_arr[0].url]"
          :src="row.cover_pic_arr[0].url"
        />
      </template>
      <!-- 阅读量（真实/虚拟） -->
      <template #click="{ row }">{{ row.click }}/{{ row.click_show }}</template>
      <!-- 发布时间 -->
      <template #publish_time="{ row }">
        <span>{{ row.publish_time | dateFormat(true) }}</span>
      </template>
      <!-- 排序 -->
      <template #sort="{ row }">
        <el-date-picker
          v-model="row.rank_time"
          placeholder="选择日期时间"
          type="datetime"
          @change="handleChangeTime(row)"
        />
        <div v-show="row.rank_time > nowTime">
          距离置顶结束:{{ row.rank_time | timeBetween }}
        </div>
      </template>
    </TablePaging>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import config from './config'
  import SearchForm from '@/components/SearchForm'
  import TablePaging from '@/components/Table'
  import tableMixins from '@/mixins/tableCommon.vue'
  import clip from '@/utils/clipboard'
  import {
    getActivityList,
    activityUpdate,
    toRecommendTopic,
    activityDelete,
  } from '@/api/operate'
  export default {
    components: {
      SearchForm,
      TablePaging,
    },
    mixins: [tableMixins],
    data() {
      return config
    },
    computed: {
      ...mapGetters({
        config: 'user/config',
      }),
      searchColumn() {
        return [
          {
            label: '状态',
            prop: 'type',
            type: 'select',
            clearable: false,
            selectList: this.typeOptions,
          },
          { label: '关键字', prop: 'keywords' },
        ]
      },
      columnList() {
        return [
          { label: '标题', prop: 'title' },
          { label: '封面', slotName: 'image', width: '150' },
          { label: '状态', prop: 'source' },
          { label: '阅读量（真实/虚拟）', slotName: 'click' },
          { label: '发布时间', slotName: 'publish_time' },
          { label: '排序', slotName: 'sort', width: '190' },
          {
            label: '操作',
            isButton: true,
            width: '450',
            btnList: [
              {
                label: '推荐',
                type: 'primary',
                eventName: 'handleRecommend',
                showName: 'recommend',
                showStatusList: [0],
              },
              {
                label: '取消推荐',
                type: 'danger',
                eventName: 'handleRecommend',
                showName: 'recommend',
                showStatusList: this.recommend(),
              },
              {
                label: '管理',
                type: 'primary',
                eventName: 'handleManage',
                showName: 'type',
                showStatusList: [1],
              },
              {
                label: '编辑',
                type: 'primary',
                eventName: 'handleEdit',
              },
              {
                label: '删除',
                type: 'danger',
                eventName: 'handleRemove',
              },
            ],
          },
        ]
      },
    },
    created() {
      this.getTableData()
    },
    methods: {
      // 查询信息流列表
      getTableData(value) {
        let params = {
          page: this.pageInfo.page,
          step: this.pageInfo.step,
        }
        params = Object.assign(
          {},
          value === undefined ? this.query : value,
          params
        )
        this.loading = true
        getActivityList(params).then((res) => {
          this.loading = false
          if (res.list.length) {
            this.list = res.list.map((item) => {
              item.rank_time = item.rank_time * 1000
              return item
            })
          } else {
            this.list = []
          }
          this.pageInfo.count = res.total
        })
      },
      // 发布新活动
      handleRelease() {
        this.$router.push({
          path: 'addActivity',
        })
      },
      // 编辑
      handleEdit(val) {
        this.$router.push({
          path: 'editActivity',
          query: {
            id: val.id,
          },
        })
      },
      // 管理
      handleManage(val) {
        this.$router.push({
          path: 'manageActivity',
          query: {
            id: val.id,
          },
        })
      },
      // 时间更新
      async handleChangeTime(row) {
        const params = {
          id: row.id,
          rank_time: row.rank_time / 1000,
        }
        const res = await activityUpdate(params)
        if (res.success) {
          this.$message.success('更新成功')
        }
      },
      // 复制推广链接
      handleCopy(val, index, buttonName, e) {
        clip(this.config.app_key + '://activitylist', e)
      },
      // 推荐/取消推荐
      async handleRecommend(val, index, buttonName) {
        const params = {
          id: val.id,
          type: buttonName == '推荐' ? 1 : 0,
        }
        const res = await toRecommendTopic(params)
        if (res.success) {
          this.$message.success('操作成功')
          this.getTableData()
        }
      },
      // 删除
      handleRemove(val, index, buttonName) {
        this.$confirm(`此操作将${buttonName}当前数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const params = {
              id: val.id,
              isDel: false,
            }
            const res = await activityDelete(params)
            this.$message({
              type: res.success ? 'success' : 'error',
              message: res.success ? `${buttonName}成功` : `${buttonName}失败`,
            })
            if (res.success) this.getTableData()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
          })
      },
      // 推荐过滤
      recommend() {
        let arr = []
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].recommend != 0) {
            arr.push(this.list[i].recommend)
          }
        }
        return arr
      },
    },
  }
</script>

<style lang="scss" scoped>
  $base: '.activity-list';
  #{$base}-container {
    #{$base}-cover {
      width: 100% !important;
      height: auto !important;
    }
    .table {
      ::v-deep .el-input__inner {
        border: 0;
        background-color: inherit;
      }
    }
  }
</style>
